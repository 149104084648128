.Button-module__Button___0OJEu {
  border: 0;
  cursor: pointer;
  height: 24px;
  border-radius: 2px;
}
.Button-module__Button___0OJEu:focus {
  outline: none;
}
.Button-module__Button___0OJEu[data-theme=dark] {
  background-color: var(--color-black);
  color: var(--color-beige);
}
.Button-module__Button___0OJEu[data-theme=dark]:focus {
  box-shadow: 0 0 0 2px var(--color-white);
}
.Button-module__Button___0OJEu[data-theme=light] {
  background-color: var(--color-white);
  color: var(--color-brown);
}
.Button-module__Button___0OJEu[data-theme=light]:focus {
  box-shadow: 0 0 0 2px var(--color-brown);
}