.Slider-module__Slider___0twby {
  position: relative;
  height: 24px;
  margin-top: -20px;
}
.Slider-module__Slider___0twby input {
  padding: 22px 0 21px 0;
  width: 100%;
  -webkit-appearance: none;
  background: transparent;
  margin: 0;
}
.Slider-module__Slider___0twby input::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin-top: -7px;
  cursor: ew-resize;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  background: var(--color-black);
  border: 1px solid var(--color-brown);
  -webkit-transition: background 0.5s ease-in-out;
  transition: background 0.5s ease-in-out;
}
.Slider-module__Slider___0twby input::-webkit-slider-runnable-track {
  cursor: normal;
  width: 100%;
  height: 1px;
  background: transparent;
}
.Slider-module__Slider___0twby input::-moz-range-thumb {
  cursor: ew-resize;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  background: var(--color-black);
  border: 1px solid var(--color-brown);
  -moz-transition: background 0.5s ease-in-out;
  transition: background 0.5s ease-in-out;
}
.Slider-module__Slider___0twby input::-moz-range-track {
  cursor: normal;
  width: 100%;
  height: 1px;
  background: transparent;
}
.Slider-module__Slider___0twby input:focus {
  outline: none;
}
.Slider-module__Slider___0twby input:focus::-webkit-slider-thumb {
  border-color: var(--color-black);
  box-shadow: 0 0 0 2px var(--color-white);
}
.Slider-module__Slider___0twby input:focus::-moz-range-thumb {
  border-color: var(--color-black);
  box-shadow: 0 0 0 2px var(--color-white);
}
.Slider-module__Slider___0twby.Slider-module__active___NSSZU input::-webkit-slider-thumb {
  background: var(--color-white);
}
.Slider-module__Slider___0twby.Slider-module__active___NSSZU input::-moz-range-thumb {
  background: var(--color-white);
}
.Slider-module__Slider___0twby.Slider-module__active___NSSZU input:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 2px var(--color-brown);
}
.Slider-module__Slider___0twby.Slider-module__active___NSSZU input:focus::-moz-range-thumb {
  box-shadow: 0 0 0 2px var(--color-brown);
}
.Slider-module__Slider___0twby::before {
  content: "";
  pointer-events: none;
  display: block;
  position: absolute;
  z-index: -1;
  top: 21.5px;
  left: 7px;
  width: calc(100% - 14px);
  height: 1px;
  background: repeating-linear-gradient(90deg, var(--color-beige), var(--color-beige) 2px, var(--color-brown) 2px, var(--color-brown) calc((100% - 2px) / 15));
}