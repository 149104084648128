:root {
  --golden-ratio: 1.61803;
  --color-black: #020204;
  --color-brown: #46413b;
  --color-mid-grey: #888888;
  --color-beige: #bebbb6;
  --color-white: #f7f7f5;
  --color-text: var(--color-brown);
  --color-text-hover: var(--color-black);
  --color-background: var(--color-beige);
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-family: sans-serif;
}

h1,
h2,
h3 {
  font-weight: normal;
  font-stretch: condensed;
  letter-spacing: 0.5px;
}

body {
  background: var(--color-background);
  color: var(--color-text);
}

#app {
  width: 100%;
  height: calc(100% - 90px);
  overflow: hidden;
}

.fullpage-loading {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center center;
}
.fullpage-loading span {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  font-size: 5vw;
  padding-right: 4.5vw;
  position: relative;
}
.fullpage-loading span::after {
  content: "...";
  position: absolute;
  right: 0;
  width: 4.5vw;
  animation: 1s linear 0s infinite normal triple-dot;
}

@keyframes triple-dot {
  0% {
    content: ".";
  }
  25% {
    content: "..";
  }
  50% {
    content: "...";
  }
  75% {
    content: "..";
  }
  100% {
    content: ".";
  }
}
header {
  background-color: var(--color-black);
  color: var(--color-beige);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  padding: 0 14px;
}
header h1 {
  margin: 0;
  font-size: 1.3em;
}
header h1 a {
  text-decoration: none;
  color: inherit;
}

footer {
  background-color: var(--color-black);
  color: var(--color-beige);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 45px;
  padding: 0 14px;
}
footer a {
  font-weight: bold;
  color: var(--color-text);
  margin-left: 10px;
}