.App-module__App___KqRm8 {
  margin: 7px 10px;
}
.App-module__App___KqRm8 h2 {
  font-size: 1.8em;
  margin: 20px 0 10px 10px;
}
.App-module__App___KqRm8 .App-module__modeLink___uCUXd {
  float: right;
  margin-top: 15px;
  margin-right: 8px;
}
.App-module__App___KqRm8 .App-module__infoWithButtons___lzQ4c {
  margin-left: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}