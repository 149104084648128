.Voice-module__Voice___4MG0N {
  display: flex;
  padding: 10px;
  border: 1px solid var(--color-mid-grey);
  margin: 5px 0;
  align-items: center;
  border-radius: 2px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  box-shadow: 0 3px 5px -5px var(--color-brown);
}
.Voice-module__Voice___4MG0N:hover {
  background: rgba(255, 255, 255, 0.25);
}
.Voice-module__Voice___4MG0N > * {
  margin-left: 10px;
}
.Voice-module__Voice___4MG0N > *:first-child {
  margin-left: 0;
  width: 25px;
}
.Voice-module__Voice___4MG0N > *:nth-child(3) {
  flex: 1;
}
.Voice-module__Voice___4MG0N .Voice-module__frequency___JcvWw {
  white-space: nowrap;
  width: 60px;
  text-align: right;
  display: block;
}